@use '@angular/material' as mat;
@use 'include-media' as *;
@use './styles_theme-colors.scss' as my-theme;

// Setting up the default theme
html {
  @include mat.theme(
    (
      color: (
        theme-type: light,
        primary: my-theme.$primary-palette,
        tertiary: my-theme.$tertiary-palette,
        use-system-variables: true,
      ),
      typography: (
        plain-family: 'var(--font-family)',
        brand-family: 'var(--font-family)',
        use-system-variables: true,
      ),
      density: (
        scale: 0,
      ),
    )
  );
}

:root {
  --mat-sys-surface-container-low: var(--color-background-white);
  --mat-sys-surface: var(--color-background-white);
  --mat-sys-surface-container: var(--color-background-white);
  --mat-sys-on-primary: var(--color-text);
  --mat-sys-corner-full: var(--border-radius);
  --mdc-filled-text-field-focus-label-text-color: var(--color-secondary-grays-600);
  @include mat.dialog-overrides(
    (
      container-max-width: 90vh,
      container-shape: 16px,
    )
  );
  @include mat.checkbox-overrides(
    (
      state-layer-size: 20px,
    )
  );
  @include mat.tooltip-overrides(
    (
      container-color: var(--color-background-accent),
      supporting-text-size: 12px,
      supporting-text-weight: 600,
    )
  );
  @include mat.button-overrides(
    (
      outlined-label-text-color: var(--color-text),
    )
  );
}

/* Material */

.mdc-checkbox__background {
  border-radius: 4px !important;
}

mat-card {
  margin: 20px !important;
  padding: 8px !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2) !important;
  border-radius: 8px;

  .mat-mdc-card-title {
    margin-bottom: 8px;
  }
  mat-card-title {
    padding: 0;

    font-family: var(--font-family);
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;

    color: var(--color-secondary-grays-600);
  }

  mat-card-content {
    font-size: 14px;
  }

  .mat-mdc-card-content:last-child,
  .mat-card-content:first-child,
  mat-card-content {
    padding: 0;
  }
}

.mat-mdc-card-title {
  padding: 32px 0;
  margin-bottom: 0;
  text-align: center;
  font-size: 24px !important;
  font-weight: bold !important;
}
.mat-mdc-card-footer {
  background: var(--color-secondary-grays-050);
  text-align: center;
  > div {
    font-size: 14px;
    padding: 30px 0;
    color: var(--color-secondary-grays-500);
    > a {
      font-weight: 500;
    }
  }
}

.mdc-tooltip__surface {
  padding: 8px 15px !important;
}

.mat-mdc-table {
  width: 100%;
  --mat-table-background-color: transparent;
  --mat-table-header-container-height: 50px;
  --mat-table-footer-container-height: 50px;
  --mat-table-row-item-container-height: 50px;

  thead th {
    background-color: var(--color-background-2);
    border-bottom: solid 1px var(--color-secondary-grays-200) !important;
    place-content: left;

    &.mat-mdc-header-cell {
      text-align: left;

      &:last-child {
        text-align: center;

        div {
          justify-content: center;
        }
      }
    }

    &.checkbox-th {
      padding: 1rem;
      width: 3rem;
    }
  }

  tbody tr {
    td {
      &:last-child {
        text-align: center;
      }

      &.checkbox-td {
        width: 3rem;
        padding: 1rem;
        border-right: solid 1px var(--color-secondary-grays-200);
        border-bottom: 1px solid var(--color-secondary-grays-200);
      }
    }
  }

  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    padding-left: 10px;
    padding-right: 10px;
    border-bottom-width: 0;

    &:last-of-type {
      padding-right: 20px;
    }
  }

  tr.mat-mdc-row,
  tr.mat-mdc-footer-row {
    td {
      border-bottom: solid 1px var(--color-secondary-grays-200);
      color: var(--color-secondary-grays-600);
    }
    &:first-child {
      border-top: solid 1px var(--color-secondary-grays-200);
    }
  }

  tbody tr.mat-mdc-row {
    transition: background-color 150ms ease;
    margin: 0 1rem;

    &:hover {
      background-color: var(--color-secondary-grays-100);
    }

    &.selected {
      background-color: var(--color-secondary-grays-100);
    }
  }

  .mat-mdc-header-cell {
    color: var(--color-secondary-grays-800);
    background-color: white;
  }
}

// Other components

.ng-select {
  .ng-select-container {
    height: var(--height-input) !important;
    min-height: var(--height-input) !important;
    font-size: 14px;
    font-family: var(--font-family);
    transition: border-color 150ms ease, border-radius 150ms ease, background-color 150ms ease;
    background-color: var(--color-background-white);
    border-radius: var(--border-radius) !important;
    cursor: text;
    border: 1px solid transparent;
    box-shadow: none;

    .ng-placeholder {
      color: var(--color-secondary-grays-700) !important;
      font-weight: 500;
    }

    .ng-value-container {
      padding-left: 1rem;

      .ng-input {
        display: none;
      }
    }
  }

  &.ng-select-multiple {
    .ng-select-container .ng-value-container .ng-input {
      padding-left: 10px !important;
      padding-right: 0 !important;
      padding-bottom: 6px !important;
    }

    .ng-select-container .ng-value-container .ng-placeholder {
      top: initial;
      padding-left: 10px;
      z-index: unset !important;
    }
  }

  .ng-select-container {
    z-index: unset !important;

    &:hover {
      background: var(--color-secondary-grays-100);
    }
  }

  &.ng-select-opened,
  &.ng-select-focused {
    .ng-select-container {
      border: 2px solid var(--color-secondary-grays-900) !important;
      box-shadow: none !important;
      background: var(--color-secondary-grays-100);
    }
  }

  .ng-arrow-wrapper {
    padding-right: 0.8rem;
    width: 32px;
  }

  .ng-arrow {
    transition: var(--transition-slow);
    border-color: var(--color-icon-input) transparent transparent !important;
  }

  .ng-clear-wrapper {
    color: var(--color-icon-input);

    &:hover .ng-clear {
      color: var(--color-warn);
      transition: var(--transition);
    }
  }

  &.ng-select-opened > .ng-select-container .ng-arrow {
    border-width: 5px 5px 2.5px;
    transform: rotateX(180deg);
  }

  // Custom class for multiple styling
  .ng-select-multiple-label {
    display: flex;
    color: var(--color-secondary-grays-700);
    margin-top: -4px;

    .number {
      padding: 6px 3px 6px 10px;
      font-weight: 500;
    }

    .clear {
      height: 24px;
      width: 24px;
      margin-top: 2px;
      margin-right: 2px;
      border-radius: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1;
      cursor: pointer;
      transition: var(--transition);
      padding-bottom: 1px;

      &:hover {
        background-color: var(--color-secondary-grays-100);
      }
    }
  }

  .image-option {
    display: flex;
    flex-direction: row;
    align-items: center;

    app-avatar {
      margin-right: 0.5rem;
    }
  }
}

.ng-dropdown-panel {
  right: 50px;
  box-shadow: var(--shadow-menu) !important;
  border-radius: var(--border-radius) !important;
  border: none !important;
  margin-top: 4px !important;

  .ng-option {
    font-size: 14px;
    font-weight: 500 !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(100% - 36px);
    display: flex !important;
    align-items: center;
    min-height: var(--height-input);
    padding: 0 16px;
    text-align: left;
    text-decoration: none;
    cursor: pointer;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    transition: var(--transition);
    color: var(--color-secondary-grays-600) !important;

    &:first-child {
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }

    &:last-child {
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }

    &.ng-option-marked {
    }

    &.ng-option-selected {
      background-color: var(--color-background-white) !important;

      span {
        font-weight: 500 !important;
      }

      :after {
        content: url('../assets/icons/check-grey.svg');
        position: absolute;
        right: 8px;
      }
    }

    .image-option {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: -4px 0;

      app-avatar {
        margin-right: 0.5rem;
      }
    }
  }
}

// Emoji mart

.emoji-mart-search input {
  border: 1px solid transparent;
}

.emoji-mart-search-icon {
  top: 11px;
}

.emoji-mart-scroll {
  scrollbar-width: thin;

  &::-webkit-scrollbar-track {
    background-color: var(--color-background-2);
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: var(--color-background-2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #959595;
    border-radius: 10px;
  }
}

.mat-mdc-menu-panel {
  box-shadow: var(--shadow-menu) !important;
  min-height: 45px !important;
}

.mat-mdc-menu-content {
  min-width: 90px !important;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  border-radius: 8px !important;
}

.mat-mdc-menu-item {
  height: 32px !important;
  min-height: 32px !important;
  padding: 8px 10px !important;
}

.mat-mdc-menu-item-text {
  --mat-menu-item-label-text-size: 14px;
  --mat-menu-item-label-text-line-height: 20px;
  --mat-menu-item-label-text-weight: 500;

  display: flex !important;
  flex-direction: row;
  align-items: center;
  color: var(--color-secondary-grays-600);

  img {
    margin-right: 10px;
  }
}
