@use '@angular/cdk';
@include cdk.text-field-autofill();

html {
  height: 100%;
  background: var(--color-background-white);
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

body {
  height: 100%;
  font-family: var(--font-family), sans-serif;
  background-color: var(--color-background);
  color: var(--color-text);
}

h1 {
  font-size: 2rem;
  margin: 0 0 1.5rem;
}

h2 {
  font-size: 1.7rem;
  margin: 0 0 1rem;
}

h3 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
  text-align: center;
  color: var(--color-secondary-grays-500);
  margin: 1rem 1rem 0 0;
}

h4 {
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: var(--color-secondary-grays-500);
  margin: 1rem 0 0 0;
}

h5 {
  font-weight: bold;
  font-size: 18px;
  line-height: 26px;
  text-align: center;
  color: var(--color-secondary-grays-600);
  margin: 0;
}

h6 {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: var(--color-secondary-grays-500);
  margin: 0;
}

input {
  line-height: 1;
  height: var(--height-input);
  padding: 0 1rem;
}

textarea {
  padding: 0.7rem 1rem;
  resize: vertical;
  min-height: var(--height-input);
}

select {
  padding: 0 1rem;
  height: var(--height-input);
}

hr {
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--color-border);
}

input,
textarea,
select {
  font-size: 14px;
  font-family: var(--font-family);
  transition: border-color 150ms ease, border-radius 150ms ease, background-color 150ms ease;

  background-color: var(--color-background-2);
  color: var(--color-text);
  border-radius: var(--border-radius);
  width: 100%;

  border: 1px solid transparent;
  // border: 1px solid hsla(0, 0%, 0%, 0.1);
  // border-top-color: transparent;
  // border-left-color: transparent;
  // border-right-color: transparent;

  @include cdk.text-field-autofill-color(transparent, var(--color-text));

  &::placeholder {
    color: var(--color-text-fade);
  }

  &:focus {
    border: 1px solid var(--color-secondary-grays-700);
    outline: none;
    border-radius: var(--border-radius);
  }
}

.ng-touched.ng-invalid:not(form) {
  border-radius: var(--border-radius);
  border-color: var(--color-warn);

  .ng-select-container {
    border-color: var(--color-warn);
  }

  &:hover,
  &:focus {
    border-color: var(--color-warn);
  }
}

.ng-valid + app-input-error,
.ng-untouched + app-input-error {
  display: none;
}

p {
  margin: 0 0 1rem;
}

input,
ng-select {
  background-color: var(--color-background-white);
  border: 1px solid #d1d5db;
  border-radius: 4px;
}
input:focus {
  border: 2px solid #374151;
}

a {
  text-decoration: none;
  margin: auto;
}
a:link {
  color: var(--color-secondary-grays-500);
}
a:hover {
  color: var(--color-secondary-grays-500);
}
a:visited {
  color: var(--color-secondary-grays-500);
}

.header-1 {
  font-weight: bold;
  font-style: normal;
  font-size: 48px;
  line-height: 48px;
}

.header-2 {
  font-weight: bold;
  font-style: normal;
  font-size: 40px;
  line-height: 48px;
}

.header-3 {
  font-weight: bold;
  font-style: normal;
  font-size: 32px;
  line-height: 40px;
}

.header-4 {
  font-weight: bold;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;
}

.header-5 {
  font-weight: bold;
  font-style: normal;
  font-size: 18px;
  line-height: 26px;
}

.d-st {
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 18px;
}
.d-t1 {
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}
.d-t2 {
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.d-b1 {
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
}
.d-b2 {
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
}
.d-il {
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  line-height: 12px;
}
