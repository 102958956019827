@charset "utf-8";
@use './styles/theme-overrides';
@use './styles/base-styles';
@use './styles/helpers';
@use 'include-media' as *;

@tailwind base;
@tailwind components;
@tailwind utilities;

// Global variables
:root {
  --font-family: 'Inter', 'Segoe UI', 'Helvetica', sans-serif;

  --color-primary: #3f51b5;
  --color-primary-hover: #6b7cd2;
  --color-primary-active: #2e3d9b;
  --color-primary-focus: var(--color-primary-active);
  --color-primary-yellow-900: #997b01;
  --color-primary-yellow-800: #cea600;
  --color-primary-yellow-600: #f2c400;
  --color-primary-yellow-500: #ffd629;
  --color-primary-yellow-200: #ffe67a;
  --color-primary-yellow-100: #fff3c2;
  --color-secondary-grays-900: #111827;
  --color-secondary-grays-800: #1f2937;
  --color-secondary-grays-700: #374151;
  --color-secondary-grays-600: #4b5563;
  --color-secondary-grays-500: #6d7585;
  --color-secondary-grays-400: #9ca3af;
  --color-secondary-grays-300: #d1d5db;
  --color-secondary-grays-200: #e5e7eb;
  --color-secondary-grays-100: #f3f4f6;
  --color-secondary-grays-050: #f9fafb;
  --color-accent: #ffab40;
  --color-warn: #f44336;
  --color-info: #50a5f1;
  --color-success: #54b676;
  --color-error-800: #9b1c1c;
  --color-error-600: #e02424;
  --color-error-500: #f05252;
  --color-error-200: #f8b4b4;
  --color-error-100: #fde8e8;
  --color-status-blue-800: #358dff;
  --color-status-blue-700: #bddaff;
  --color-status-blue-500: #d6e8ff;
  --color-status-blue-300: #e6f1ff;
  --color-status-orange-800: #ef6c00;
  --color-status-orange-700: #f57c00;
  --color-status-orange-500: #ff9800;
  --color-status-orange-300: #ffb74d;
  --color-status-orange-200: #ffcc80;
  --color-status-purple-800: #6a4de6;
  --color-status-purple-700: #d5cdfa;
  --color-status-purple-500: #e1dbfa;
  --color-status-purple-300: #eeebfa;
  --color-status-green-800: #248a42;
  --color-status-green-700: #a6e0b8;
  --color-status-green-500: #c5f6d4;
  --color-status-green-300: #e6f7eb;
  --color-condition-generic: #d1d5db;
  --color-condition-poor: #fb7777;
  --color-condition-fair: #fb9379;
  --color-condition-good: #ffa273;
  --color-condition-good-plus: #ffb265;
  --color-condition-very-good: #ffcb65;
  --color-condition-very-good-plus: #ffe074;
  --color-condition-near-mint: #c5f06e;
  --color-condition-mint: #98ee79;
  --color-condition-mint-stroke: #55b50a;
  --color-border: rgba(0, 0, 0, 0.2);

  --scrollbar-width: 0px !important;

  --color-primary-text: white;
  --color-text: hsl(0, 0%, 10%);
  --color-text-fade: hsl(0, 0%, 60%);
  --color-icon-input: #5c5c5c;

  --color-background: #ffffff;
  --color-background-2: #f3f4f6;
  --color-background-grey: var(--color-secondary-grays-100);
  --color-background-white: #ffffff;
  --color-background-border: #e5e7eb;
  --color-background-2-hover: hsl(0, 0%, 90%);
  --color-background-accent: #222b45;
  --color-background-accent-hover: #373f58;
  --color-active-button-border: #111827;

  --shadow: 0px 1px 15px 1px rgba(65, 65, 65, 0.1);
  --shadow-menu: 0px 4px 20px 1px rgba(65, 65, 65, 0.15), 0px 4px 8px -3px rgba(65, 65, 65, 0.3);
  --shadow-table: 0px 1px 3px rgba(0, 0, 0, 0.2);
  --border-radius: 4px;
  --transition: all 150ms ease;
  --transition-slow: all 300ms ease;

  --height-input: 42px;
  --height-toolbar: 80px;
  --height-toolbar-mobile: 53px;
}

.card {
  padding: 1.5rem;
  box-shadow: var(--shadow);
  // border: 2px solid var(--color-background-2);
  border-radius: 8px;

  @include media('<tablet') {
    padding: 0.5rem;
  }
}

.form-group {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  position: relative;

  p.prefix,
  p.suffix {
    font-weight: 600;
  }

  .prefix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 12px;
    color: var(--color-icon-input);
    margin: 0;

    & ~ input {
      padding-left: 48px;
    }
  }

  .suffix {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
    color: var(--color-icon-input);
    margin: 0;

    & ~ input {
      padding-right: 48px;
    }

    &.clear-icon {
      font-size: 15px;
      cursor: pointer;
      transition: var(--transition);

      &:hover {
        color: var(--color-warn);
      }
    }
  }

  button.suffix {
    height: 32px;
    width: 32px;
    line-height: 32px;

    i {
      font-size: 20px;
    }
  }

  label {
    margin-bottom: 0.3rem;
    font-size: 12px;
    font-weight: 600;
  }
}

.page-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2rem;

  @include media('<tablet') {
    justify-content: flex-start;
    flex-wrap: wrap;

    .header-tools {
      margin-top: 0.7rem;
      width: 100%;
    }
  }

  app-back {
    margin-right: 1rem;
  }

  h1 {
    margin: 0;
    flex-grow: 1;
    font-weight: bold;
    font-size: 48px;
    line-height: 48px;
  }

  h2 {
    font-weight: bold;
    font-size: 40px;
    line-height: 48px;
  }

  .header-tools {
    display: flex;

    > *:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}

.table-wrapper {
  width: 100%;

  .table-selection {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 0 6px;
    background-color: white;
    color: var(--color-secondary-grays-600);
    border-radius: 16px 16px 0px 0px;
    height: 72px;
    width: 100%;
    min-width: 320px;
    max-height: 0;
    overflow: hidden;
    transition: var(--color-secondary-grays-600);
    box-shadow: 0px -2px 20px rgba(0, 0, 0, 0.06);

    img {
      width: 24px;
      height: 24px;
    }

    .form-group {
      margin: 0 10px 0 auto;
    }

    .label-details {
      display: block;
      width: 130px;
      margin: 0 4px;

      span {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        width: auto;
        height: 40px;
      }
    }

    &.visible {
      max-height: 72px;

      & + .table-container .mat-mdc-table thead th {
        &:first-child {
          border-radius: 0 0 0 8px;
        }

        &:last-child {
          border-radius: 0 0 8px 0;
        }
      }
    }
  }

  .table-container {
    width: 100%;
    overflow: auto;
    min-height: 100px;

    .empty-table-row {
      height: 660px !important;
    }
  }
}

.snackbar {
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  font-weight: 500;
  padding: 8px 12px;
}
.mat-mdc-snack-bar-container {
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: white;
}

.success-snackbar {
  --mdc-snackbar-container-color: var(--color-status-green-800);
  color: white;
}

.warning-snackbar {
  --mdc-snackbar-container-color: var(--color-primary-yellow-100);
  --mdc-snackbar-supporting-text-color: var(--color-text);
  color: var(--color-primary-yellow-900);
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version.*/
  .mdc-button__label {
    color: var(--color-primary-yellow-600) !important;
  }
}

.error-snackbar {
  --mdc-snackbar-container-color: var(--color-error-500);
  color: white;
}

.mat-mdc-dialog-surface {
  padding: 1rem;
}

.overflow-dialog {
  overflow: auto;
}

.only-mobile {
  @include media('>=desktop') {
    display: none !important;
  }
}

.only-desktop {
  @include media('<desktop') {
    display: none !important;
  }
}

textarea:focus {
  outline: none;
  border: 0;
}

.ng-select {
  .ng-arrow-wrapper {
    .ng-arrow {
      display: none !important;
    }
  }
}

.ng-select span.ng-arrow-wrapper:before {
  content: url('assets/icons/arrow-down.svg') !important;
  display: inline-block;
  text-rendering: auto;
}

.icon-btn {
  &:hover {
    background: var(--color-secondary-grays-100);
  }
}

.card-title > .mat-button-toggle-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  border: none;
  height: auto;
  .mat-button-toggle-checked {
    background-color: var(--color-secondary-grays-100) !important;
    color: var(--color-secondary-grays-900) !important;
  }
  .mat-button-toggle {
    color: var(--color-secondary-grays-600);
    border-left: none !important;
    padding: 0;
    margin-right: 2px;
    border-radius: 8px !important;
  }

  > .mat-mdc-unelevated-button {
    font-size: 16px;
    font-weight: 500;
  }
}

ngx-extended-pdf-viewer .toolbarField.pageNumber {
  max-height: 18px;
}

mat-select-country {
  .mat-mdc-form-field {
    width: 100%;
    flex-direction: row;
    .mat-mdc-form-field-wrapper {
      width: 100%;
      padding-bottom: 0 !important;
    }
  }

  input {
    width: 100% !important;
    border: 1px solid var(--color-secondary-grays-300) !important;
  }

  input:focus {
    border: 2px solid var(--color-secondary-grays-800) !important;
  }
  .mat-mdc-form-field-suffix {
    position: absolute;
    right: 10px;
    top: 16px;
  }
  .mat-mdc-form-field-label {
    display: none !important;
  }
  .mat-mdc-form-field-underline {
    display: none;
  }
}

.status-flag {
  margin-left: 8px;
  position: relative;
  top: 8px;
}

.version {
  position: absolute;
  right: 4px;
  bottom: 4px;
}
